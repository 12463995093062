import { SdfPromoBox, SdfButton } from "@synerg/react-components";

const boxProps = {
  header: "Recommend API Central to your HR team",
  message: "An HR practitioner must activate API Central on your organization’s behalf. Share the app listing page to help them get started.",
  illustrationName: "info",
  layout:"horizontal"
};

const RecommendApiCentral = () => {
  const buttonProps = {
    onClick: () => "TODO",
    slot: "cta"
  }

  return (
    <div className="w-11/12 md:w-4/5">
      <SdfPromoBox {...boxProps}>
        <SdfButton {...buttonProps}>Recommend</SdfButton>
      </SdfPromoBox>
    </div>
  );
};

export default RecommendApiCentral;
