import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {
    SfcShell,
} from '@synerg/react-components/dist/sfc-components';
import {
    SdfPageLayout
} from '@synerg/react-components';
import TopNav from '../topNav'
import Routes from '../../routes';
import AppBar from '../AppBar';
import ScrollToTop from "../../utility/ScrollToTop";


export default function OneuxLayout() {
    return (
        <Router basename={`${process.env.REACT_APP_ROOT_PATH}`}>
            <ScrollToTop/>
            <SfcShell>
                <AppBar/>
                <TopNav/>
                <SdfPageLayout className="min-h-screen">
                    <Routes/>
                </SdfPageLayout>
                {/* <Footer/> */}
            </SfcShell>
        </Router>
    );
}
