import { Link } from "react-router-dom";

import "./HeroImage.css";

/**
 * @typedef {{
 *  title: string,
 *  linkText: string,
 *  pathName: string,
 *  className: string,
 * }} HeroLinkProps
 */

/**
 * @param {HeroLinkProps} props
 */
const HeroLink = ({ title, linkText, pathName, className, ...rest }) => {
  const linkClassName = `absolute rounded-full bg-default px-6 py-1 shadow-8${
    className ? ` ${className}` : ""
  }`;

  return (
    <div className={linkClassName} {...rest}>
      <div className="text-heading-02 whitespace-nowrap">{title}</div>
      <Link
        to={pathName}
        className="text-heading-01 no-underline whitespace-nowrap"
      >
        {linkText}
      </Link>
    </div>
  );
};

/**
 * @param {{
 *  src: string | File;
 *  alt: string;
 *  linkList: Array<HeroLinkProps>;
 *  partnerLanding: boolean;
 * }} props
 */
const HeroImage = ({ alt, linkList, partnerLanding,  ...rest }) => (
  <div className="flex justify-center">
    <div className="relative inline-block">
      <div className={`absolute pt-3${partnerLanding ? "" : " bg-action-destructive-50 hero-image-shape hero-image-outline"}`} />
      {linkList.map((item, index) => (
        <HeroLink key={`hero-link-${index}`} {...item} />
      ))}
      <img
        className={partnerLanding ? "max-h-lg" : "hero-image-shape max-h-full"}
        alt={alt}
        {...rest}
      />
    </div>
  </div>
);

export default HeroImage;
