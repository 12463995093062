import Header from "./Header";
import ApiLibrary from "./ApiLibrary";
import BuildingIntegrations from "./BuildingIntegrations";
import GetStarted from "./GetStarted";
import ExpertConsulting from "./ExpertConsulting";
import RecommendApiCentral from "./RecommendApiCentral";
import AvailableGuides from "./AvailableGuides";
import BuyNow from "./BuyNow";
import { guideItemList } from "./guideItemList";

const ClientIntegration = () => (
  <>
    <BuyNow />
    <div className="overflow-hidden flex flex-col items-center gap-12 py-24">
      <Header />
      <ApiLibrary />
      <BuildingIntegrations />
      <GetStarted />
      <ExpertConsulting />
      <RecommendApiCentral />
      <AvailableGuides guideItemList={guideItemList}/>
    </div>
  </>
);

export default ClientIntegration;
