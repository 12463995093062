import React from 'react';
import ArticlesListWithFilter from "../../components/articles/ArticlesListWithFilter";
import {FILTERS} from "../../components/articles/filters";

// const breadcrumbPath = [
//     { name: 'Home', route: '/home' },
//     { name: 'Key Concepts', route: '/getting-started/key-concepts' }
// ]

function KeyConceptsPage() {
    return (
        <ArticlesListWithFilter
            pageTitle="Key Concepts"
            navigatePath="/getting-started/key-concepts"
            // breadcrumbPath={breadcrumbPath}
            fieldsDescriptions={['TOPIC', 'LAST UPDATED']}
            filtersDefinition={[
                FILTERS.inputWithoutMax,
                FILTERS.sortBy,
            ]}
            customArticlesQuery={{
                tags: ['keyconcept', 'howto'],
                matchAnyTags: true
            }}
        />
    )
}

export default KeyConceptsPage;
