export const guideItemList = [
    {
      header: "Human resources",
      message:
        "Sensitive and non-sensitive personal data, demographic, employment and pay-related data",
      pathName: "/TODO/guide",
    },
    {
      header: "Time & labor management",
      message:
        "Time and attendance, workforce management,  timeclock punches, shift scheduling",
      pathName: "/TODO/guide",
    },
    {
      header: "Payroll management",
      message:
        "Data needed for payroll processing, such as regular hours and overtime hours, as well as processed data, such as earnings, deductions, taxes and more",
      pathName: "/TODO/guide",
    },
    {
      header: "Recruiting",
      message:
        "Data related to job requisitions, applications and assessments, background checks, hiring, onboarding",
      pathName: "/TODO/guide",
    },
    {
      header: "Benefits management",
      message:
        "Employee benefits, deductions, dependents, and insurance management",
      pathName: "/TODO/guide",
    },
  ];
  