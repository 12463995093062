import { SdfButton } from "@synerg/react-components";
import React from "react";
import "./style.css";
import "../ClientIntegration/Header.css";
import { imageProps } from "./utility/constants";
import HeroImage from "../shared/HeroImage";
import { useNavigate } from "react-router-dom";

export function MarketplaceSection() {
  const navigate = useNavigate();
  return (
    <div className="w-11/12 md:w-4/5">
      <div className="flex flex-col xl:flex-row">
        <div className="text-center mt-12">
          <h1 className="font-black mb-12 text-heading-08 text-left">
            Interested in becoming an ADP Marketplace partner?
          </h1>
          <p className="sm:w-xl sm:text-heading-04 text-left">
            Learn about the application process, explore ways to integrate your
            solution with ADP, and more.
          </p>
        </div>
        <div className="xl:mr-20 flex-shrink-0 xl:w-xl xl:py-0">
          <HeroImage {...imageProps} />
        </div>
      </div>

      <div className="bg-action-primary-900 rounded-lg text-inverse-default px-16 xl:px-32 py-8 gap-6 shadow-8">
        <div className="flex flex-col xl:flex-row justify-between">
          <div>
            <div className="text-brand-secondary text-heading-01 font-bold mt-4 uppercase">
              Api inventory
            </div>
            <div className="text-heading-06 font-bold mb-4">
              Explore APIs by ADP product
            </div>
            <p className="xl:w-2xl font-weight-450 text-body-03 mb-8">
              View code samples of requests and responses from APIs for ADP
              products including ADP Workforce Now, ADP Vantage HCM, and more.
            </p>
          </div>
          <div className="flex flex-shrink-0">
            <SdfButton
              emphasis="primary"
              className="w-sm my-20"
              size="large"
              onClick={() =>
                navigate("/apis/api-explorer")
              }
            >
              Browse APIs
            </SdfButton>
          </div>
        </div>
      </div>
    </div>
  );
}
