import HeroImage from "../shared/HeroImage";
import image from "../../assets/images/hero_desktop.png";
import "./Header.css";

const imageProps = {
  src: image,
  alt: "ADP API Central", // TODO - improve alt text
  partnerLanding: false,
  linkList: [
    {
      title: "Recruiting",
      linkText: "View Related APIs",
      pathName: "/apis/api-explorer?category=recruiting",
      className: "hero-image-recruiting",
    },
    {
      title: "Benefits",
      linkText: "View Related APIs",
      pathName: "/apis/api-explorer?category=benefits",
      className: "hero-image-benefits",
    },
    {
      title: "Onboarding",
      linkText: "View Related APIs",
      pathName: "/apis/api-explorer?category=onboarding",
      className: "hero-image-onboarding",
    },
    {
      title: "Payroll",
      linkText: "View Related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn?domain=payroll",
      className: "hero-image-payroll",
    },
    {
      title: "Employee data",
      linkText: "View Related APIs",
      pathName: "/apis/api-explorer?category=employee-data",
      className: "hero-image-data",
    },
    {
      title: "Time and attendance",
      linkText: "View Related APIs",
      pathName: "/apis/api-explorer?category=time-and-attendance",
      className: "hero-image-time",
    },
    {
      title: "Employee qualifications",
      linkText: "View Related APIs",
      pathName: "/apis/api-explorer?category=employee-qualification",
      className: "hero-image-qualifications",
    },
  ],
};

const Header = () => (
  <div className="w-11/12 md:w-4/5 pb-12">
    <div className="flex flex-col xl:flex-row">
      <div className="xl:mr-16 my-auto">
        <h1 className="m-0 pb-8 text-heading-08">
          Integrate your HR and business solutions with ADP APIs
        </h1>
        <p className="m-0 text-heading-04">
          ADP® API Central provides instant access to APIs, tools and services,
          all in one place.
        </p>
      </div>
      <div className="flex-shrink-0 h-xs w-full pt-16 xl:w-xl xl:py-0">
        <HeroImage {...imageProps} />
      </div>
    </div>
  </div>
);

export default Header;
