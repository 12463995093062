import {SdfLink} from "@synerg/react-components"
import React from "react"

import vantageLogo from '../../assets/images/vantage-app-logo.svg';
import wfnLogo from '../../assets/images/wfn-app-logo.svg';
import wfnNextLogo from '../../assets/images/wfn-nextgen.png';
import runLogo from '../../assets/images/run-app-logo.svg';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {selectProduct} from "../../store";
import recruitingLogo from '../../assets/images/recruiting-management.png';
import embeddedLogo from '../../assets/images/embedded-payroll.png';
import nextGenHcmLogo from '../../assets/images/adp-lyric-img.png';

const LOGOS = {
    "hcm-offrg-wfn": wfnLogo,
    "pr-offrg-run": runLogo,
    "hcm-offrg-vantg": vantageLogo,
    "hcm-offrg-wfn.next.gen": wfnNextLogo,
    "tal-offrg-tal.rcrtg": recruitingLogo,
    "pr-offrg-adp.embdd.pr":embeddedLogo,
    "hcm-offrg-lifion": nextGenHcmLogo
}

export default function ApiExplorerPopularProducts({products}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isPopularProduct = (product) => product.isPopular

    const onHandleNavigation = (product) => {
        dispatch(selectProduct(product));
        navigate(`/apis/api-explorer/${product.key}`);
    }

    return (
        <div className='grid gap-8 md:gap-12 lg:grid-cols-2 sm:grid-cols-1 grid-rows-2 pt-4'>
            {products && products.filter(isPopularProduct).map((product, index) => (
                <div className='md:w-10/12 lg:w-10/12 flex flex-col md:flex-row items-start md:items-center cursor-pointer' key={index}
                     onClick={() => onHandleNavigation(product)}>
                    <img className='w-20 md:min-w-32 mb-2 md:mb-0 mr-3' src={LOGOS[product.key]} alt={product.name}/>
                    <div className='h-full flex flex-col justify-between'>
                        <div>
                            <div className=" text-heading-03 font-bold">{product.title}</div>
                            <div
                                className="font-normal text-md text-alert-neutral pt-2 md:pr-10 lg:pr-10">{product.description}</div>
                        </div>
                        <div className="text-action-primary font-normal text-md mt-2 md:mt-0">
                            <SdfLink
                                className="text-heading-02 no-underline cursor-pointer font-medium">
                                View APIs
                            </SdfLink>
                        </div>
                    </div>
                </div>
            ))
            }
        </div>
    )
}
