import React, { useState } from "react";
import "../welcome-page/style.css";
import "./style.css";
import { SdfButton, SdfIcon } from "@synerg/react-components";

export function PartnerSection({ timeline2 }) {
  const [stage, setStage] = useState(0);
  const goNext = () => setStage(stage + 1);
  const goPrev = () => setStage(stage - 1);

  const handleDotClick = (index) => {
    setStage(index);
  };

  return (
    <div>
      <div className="text-center pb-2 pt-10 font-bold text-heading-06">
        The path to becoming an ADP Marketplace partner
      </div>
      <div className="text-center pb-12 text-body-02 font-weight-450">
      Interested in selling your solution on ADP Marketplace? Here’s what you can expect if you are accepted into our exclusive program.
      </div>
      <div className="flex xl:w-3/4 m-auto bg-action-default-50 rounded-l-lg">
        <div className="grid-container w-11/12 pl-4 pr-4 pb-4 pt-16">
          <>
            <div className="grid grid-cols-5">
              {timeline2.map((item, index) => (
                <div
                  key={index + timeline2.length}
                  className="grid-item first-row"
                >
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src={item.icon}
                      alt={`${item.title} icon`}
                      className="w-12 h-12"
                    />
                    <div className="text-center font-bold text-heading-04 mb-4">
                      {item.title}
                    </div>
                  </div>
                </div>
              ))}

              <div className="relative col-span-5">
                <div className="timeline-line"></div>
                <div className="flex justify-between w-full">
                  {timeline2.map((item, index) => (
                    <div key={index} className="timeline-marker-wrapper">
                      <div
                        className={`cursor-pointer timeline-marker ${
                          stage === index
                            ? "ring-8 ring-white border-black shadow-lg"
                            : ""
                        }`}
                        onClick={() => handleDotClick(index)}
                        aria-label={`Go to ${item.title} phase`}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>

              {timeline2.map((item, index) => (
                <div
                  key={index + timeline2.length}
                  className="grid-item second-row"
                >
                  <ul className="text-left p-4 mt-0 mb-8 space-y-2 custom-weight text-md">
                    {item.tasks.map((task, taskIndex) => (
                      <li
                        key={taskIndex + index}
                        className={`${
                          item.title === "Integrations" && taskIndex > 0
                            ? "list-disc ml-2"
                            : "list-none pb-2"
                        }`}
                      >
                        {task}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              <div className="col-span-3 col-start-3 go-live-readiness-section">
                <h2 title="none" className="header">
                  heading
                </h2>
                <p className="text-center bg-action-default-100 mb-0 pt-4">
                  Go-Live Readiness
                </p>
                <div className="flex justify-between pl-4 pr-4 bg-action-default-100">
                  <ul className="text-left font-normal">
                    <li>App listing content and pricing configuration</li>
                    <li>Listing terms of service</li>
                  </ul>
                  <ul className="text-left font-normal">
                    <li>Finance and billing</li>
                    <li>Operational readiness</li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        </div>

        <div className="w-1/4 bg-action-default-200 p-8 rounded-r-lg flex flex-col">
            <div className="text-heading-05">
              {timeline2[stage].title}
            </div>
            <div className="m-0 text-body-02 flex-grow whitespace-pre-wrap">
            {timeline2[stage].description
              .replace(/\. (?=[A-Z])/g, '.\n\n')
              .split('\n\n')
              .map((paragraph, index) => (
                <p key={index}>{paragraph.trim()}</p>
              ))}
            </div>

            <div className="flex gap-2">
              <SdfButton
                variant="text"
                color="primary"
                action="standard"
                size="sm"
                onClick={goPrev}
                disabled={stage === 0 ? true : null}
              >
                <SdfIcon icon="nav-move-left" />
                Prev
              </SdfButton>
              <div className="flex-grow" />
              <SdfButton
                variant="text"
                color="primary"
                action="standard"
                size="sm"
                onClick={goNext}
                disabled={stage === timeline2.length - 1 ? true : null}
              >
                Next
                <SdfIcon icon="nav-move-right" />
              </SdfButton>
            </div>
        </div>
      </div>
    </div>
  );
}