import gears from "../../../assets/images/partner-landing/gears.svg";
import tablet from "../../../assets/images/partner-landing/tablet.svg";
import target from "../../../assets/images/partner-landing/target.svg";
import connections from "../../../assets/images/partner-landing/connections.svg";
import image from "../../../assets/images/partner-landing/partner-landing-page-img.png";

export const CLIENT_OPTIONS = [
  {
    icon: connections,
    title: "A substantial number of shared clients",
  },
  {
    icon: target,
    title: "Differentiated HCM solutions",
  },
  {
    icon: gears,
    title:
      "A SaaS technology platform that can be integrated with ADP systems via APIs",
  },
  {
    icon: tablet,
    title: "Digital purchasing capabilities",
  },
];

export const imageProps = {
  src: image,
  alt: "header",
  partnerLanding: true,
  linkList: [
    {
      title: "ADP Workforce Now",
      linkText: "View Related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn",
      className: "wfn",
    },
    {
      title: "ADP Workforce Now Next Generation",
      linkText: "View Related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-wfn.next.gen",
      className: "nextgen",
    },
    {
      title: "RUN Powered by ADP",
      linkText: "View Related APIs",
      pathName: "/apis/api-explorer/pr-offrg-run",
      className: "run",
    },
    {
      title: "ADP Next Gen HCM",
      linkText: "View Related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-lifion",
      className: "hcm",
    },
    {
      title: "ADP Vantage HCM",
      linkText: "View Related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-vantg",
      className: "vantage",
    },
    {
      title: "ADP Enterprise HR",
      linkText: "View Related APIs",
      pathName: "/apis/api-explorer/hcm-offrg-ent",
      className: "enterprise",
    },
    {
      title: "ADP Smart Compliance",
      linkText: "View Related APIs",
      pathName: "/apis/api-explorer/cp-offrg-smart.cp",
      className: "compliance",
    },
    {
      title: "ADP Recruiting Management",
      linkText: "View Related APIs",
      pathName: "/apis/api-explorer/tal-offrg-tal.rcrtg",
      className: "recruiting",
    },
  ],
};

export const GUIDE_ITEM_LIST = [
  {
    header: "Human resources",
    message:
      "Sensitive and non-sensitive personal data, demographic, employment and pay-related data",
    pathName: "/guides/product-integration-guides?useCaseTags=Human-Resources",
  },
  {
    header: "Time & labor management",
    message:
      "Time and attendance, workforce management,  timeclock punches, shift scheduling",
    pathName: "/guides/product-integration-guides?useCaseTags=Time-Labor-Management",
  },
  {
    header: "Payroll management",
    message:
      "Data needed for payroll processing, such as regular hours and overtime hours, as well as processed data, such as earnings, deductions, taxes and more",
    pathName: "/guides/product-integration-guides?useCaseTags=Payroll-Management",
  },
  {
    header: "Recruiting",
    message:
      "Data related to job requisitions, applications and assessments, background checks, hiring, onboarding",
    pathName: "/guides/product-integration-guides?useCaseTags=Recruiting",
  },
  {
    header: "Benefit management",
    message:
      "Employee benefits, deductions, dependents, and insurance management",
    pathName: "/guides/product-integration-guides?useCaseTags=Benefit-Management",
  },
];
