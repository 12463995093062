import React from "react";
import {
  SdfBox,
  SdfTooltip,
  SdfLink,
  SdfAlert,
} from "@synerg/react-components";
import "./style.css";

export function AccessData({ availableAPIs, tableTitles }) {
  return (
    <div className="flex flex-col m-auto max-w-3/4 px-12 py-12 mt-16 items-center">
      <div className="text-center">
        <label className="sm:text-heading-06 md:text-heading-06 lg:text-heading-06 xl:text-heading-06 font-black px-8">
          Unleash the power of ADP data
        </label>
        <p className="body-02 sm:text-body-02 md:text-body-02 lg:text-body-02 xl:text-body-02">
          ADP provides access to robust APIs that span the employee life cycle —
          HR, time and labor management, payroll, and more. Simply choose which
          fields you want to use in your application. Here"s an example of the
          data you can get from the comprehensive /hr/v2/workers API, used with
          the{" "}
          <SdfLink href="https://developers.adp.com/build/api-explorer/hcm-offrg-wfn">
            ADP Workforce Now®
          </SdfLink>{" "}
          payroll platform.
        </p>
        <p className="font-bold pt-6 text-left sm:text-heading-05 md:text-heading-05 lg:text-heading-05 xl:text-heading-05">
          People Data Sample* of /hr/v2/workers
        </p>
        <div className="flex grid xl:grid-cols-2 xl:grid-cols-2 gap-4 max-w-full">
          {availableAPIs.map((tableData, index) => (
            <section key={index}>
              <SdfBox
                id={`demo-component-${index + 1}`}
                variant="shadowed"
                spacing="loose"
              >
                <p className="font-bold mt-2 text-left sm:text-heading-04 md:text-heading-04 lg:text-heading-04 xl:text-heading-04">
                  {tableTitles[index]}
                </p>
                <div className="table-custom-style">
                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th className="font-bold">Field Name</th>
                        <th className="font-bold">Example Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, i) => (
                        <tr key={i}>
                          <td>
                            {row.fieldName.length >= 40 ? (
                              <SdfTooltip
                                attachmentPoint="top-end"
                                id={`fieldname-tooltip-${i}`}
                              >
                                <span slot="tooltip-target">
                                  {`${row.fieldName.slice(0, 40)}...`}
                                </span>
                                <span className="break-words">
                                  {row.fieldName}
                                </span>
                              </SdfTooltip>
                            ) : (
                              <span>{row.fieldName}</span>
                            )}
                          </td>
                          <td>
                            {row.exampleValue.length >= 40 ? (
                              <SdfTooltip id={`value-tooltip-${i}`}>
                                <span slot="tooltip-target">
                                  {`${row.exampleValue.slice(0, 40)}...`}
                                </span>
                                <span>{row.exampleValue}</span>
                              </SdfTooltip>
                            ) : (
                              <span>{row.exampleValue}</span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </SdfBox>
            </section>
          ))}
        </div>
        <section className="flex h-full text-left mt-10 text-lg">
          <SdfAlert
            icon="alert-notification"
            status="info"
            type="banner"
            size="md"
            closeable="true"
          >
            <span slot="title" className="font-bold">
              ADP takes data privacy seriously. Representative data fields and
              example values shown are used for illustrative purposes only. This
              is neither an exhaustive list of data fields available from this
              API nor an actual client’s data.
            </span>
            <span className="font-normal">
              Please refer to the API’s JSON request and response for a full
              list of data fields and sample data returned by the API as shown
              within{" "}
              <SdfLink
                external="true"
                href="https://developers.adp.com/build/api-explorer/hcm-offrg-wfn"
              >
                API Explorer for ADP Workforce Now
              </SdfLink>
              .
            </span>
          </SdfAlert>
        </section>
        <p className="font-thin text-left pt-2 pl-6 pr-6">
          *Sample data fields taken from a JSON response from ADP Workforce Now.
          Note the number of supported data fields for a given API will vary by
          product.
        </p>
      </div>
    </div>
  );
}
