import React, { Fragment, useEffect, useState } from "react";
import {useNavigate, useSearchParams} from 'react-router-dom';
import { SfcShellTopNav } from "@synerg/react-components/dist/sfc-components";
import getMenu from "../routes/menu";
import {findInNestedArray} from "../utility/ObjectUtil";
import './articles/previews/GuidesPrintView/WholeGuidePrintView/style.css'
import { useFetchProductsQuery } from "../store";
import { SdfAlert } from "@synerg/react-components";

function TopNav() {
	const {data, isLoading, isError} = useFetchProductsQuery();
	const [allRoutes, setAllRoutes] = useState([]);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams()
	const isPrintView = Boolean(searchParams.get('printView'))


	useEffect((() => {
		if (!isLoading) {
			const [menu] = getMenu(data);
			setAllRoutes(menu.appRoutes);
		}
	}), [isLoading]);


	const activeItem = findInNestedArray(allRoutes, 'pathname', window.location.pathname, false) ||
	findInNestedArray(allRoutes, 'pathname', window.location.pathname, true);

	return (!isPrintView && allRoutes && !isLoading && allRoutes &&
	<Fragment>
		{isError && <SdfAlert closeable status="error" toast={true}>
			An error occurred when fetching all Product Integrations.
		</SdfAlert>}
	<SfcShellTopNav
		id='nav'
		slot='nav'
		left-nav-type=""
		mega-menu-layout=""
		activeItem={activeItem}
		role='navigation'
		menuProvider={allRoutes}
		onSfcNavigate={(event) => {
			event.preventDefault();
			navigate(event.detail.pathname);
	}}
	data-testid='top-nav'
	/>
	</Fragment>
	)
}


export default TopNav;
