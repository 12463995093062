
function fulfillRoutes(routes) {
    return routes.map((route) => {
        if (route.hasChildren && route.children) {
            // recursively calls for children routes
            route.children = fulfillRoutes(route.children);
        }

        if (route.pathname) {
            route.href = route.pathname
        }

        return route;
    })
}

function buildNorthAmericaOfferings(productsData) {

    const naProducts = productsData && productsData.length > 0 ?
    productsData.filter((product) => !!product.northAmericaOffering).map((product) => {
        return {
					id: product.key,
	        title: product.title,
          pathname:`/apis/api-explorer/${product.key}`
        }
    })
    :
    [{
        id: 'hcm-offrg-wfn',
        title: 'ADP Workforce Now®',
        pathname: '/apis/api-explorer/hcm-offrg-wfn'
    }];

    return {
        id: 'apis-na',
        title: 'APIs by Product (North America)',
        hasChildren: true,
        children: [
        ...naProducts
        ]
    }
}

function buildInternationalOfferings(productsData) {

    const intProducts = productsData && productsData.length > 0 ?
    productsData.filter((product) => !!product.internationalOffering).map((product) => {
        return {
            id: product.key,
            title: product.title,
            pathname:`/apis/api-explorer/${product.key}`
        }
    })
    :
    [{
        id: 'pr-offrg-adp.link.prem',
        title: 'ADP® Link',
        pathname: '/apis/api-explorer/pr-offrg-adp.link.prem'
    }];

    return {
        id: 'apis-int',
        title: 'APIs by Product (International)',
        hasChildren: true,
        children: [
            ...intProducts
        ]
    }

}

function getApiSubMenu(productsData){
    let naMenu;
    let intMenu;

    naMenu = buildNorthAmericaOfferings(productsData);
    intMenu = buildInternationalOfferings(productsData);

    const apiSubMenuChildren = [
        {
            id: 'api-explorer',
            title: 'API Explorer',
            hasChildren: true,
            children: [
                {
                    id: 'apis-discover',
                    title: 'Discover ADP API integrations',
                    pathname: '/apis/api-explorer'
                }
            ]
        }
    ];



    const addIfTruthy = (array, item) => {
        if (item) {
            array.push(item);
        }
    }

    addIfTruthy(apiSubMenuChildren, naMenu)
    addIfTruthy(apiSubMenuChildren, intMenu)


    console.log('useMenu:', apiSubMenuChildren);
    return apiSubMenuChildren;
}


export default function getMenu(productsData = []) {


    const appRoutes = [
        {
            id: 'home',
            title: 'Home',
            pathname: '/',
            default: true,
        },
        {
            id: 'getting-started',
            title: 'Getting started',
            hasChildren: true,
            children: [
                {
                    id: 'getting-started_0',
                    title: 'API Central Clients',
                    hasChildren: true,
                    children: [
                        {
                            id: 'client-integration-overview',
                            title: 'Client integration overview',
                            pathname: '/getting-started/client-integration-overview',
                        },
                        {
                            id: 'getting-started-as-a-client',
                            title: 'Getting started as a client',
                            pathname: '/guides/product-integration-guides/api-central-quick-start-guide',
                        }
                    ]
                },
                {
                    id: 'getting-started_1',
                    title: 'ADP Marketplace Partners',
                    hasChildren: true,
                    children: [
                        {
                            id: 'partner-integration-overview',
                            title: 'Partner integration overview',
                            pathname: '/getting-started/partner-integration-overview',
                        },
                        {
                            id: 'getting-started-as-a-partner',
                            title: 'Getting started as a partner',
                            pathname: '/guides/marketplace-integration-guides/partner-development-learning-guide',
                        }
                    ]
                },
                {
                    id: 'getting-started_2',
                    title: 'Key Concepts',
                    hasChildren: true,
                    children: [
                        {
                            id: 'articles',
                            title: 'Learn about API Basics, Usage, Security and Troubleshooting',
                            pathname: '/getting-started/key-concepts',
                        }
                    ]
                },
            ]
        },
        {
            id: 'apis',
            title: 'APIs',
            hasChildren: true,
            expanded: true,
            children: getApiSubMenu(productsData)
        },
        {
            id: 'guides',
            title: 'Guides',
            hasChildren: true,
            expanded: true,
            children: [
                {
                    id: 'quick-start-guides',
                    title: 'Quickstart Guides',
                    hasChildren: true,
                    children: [
                        {
                            id: 'api-central-guide',
                            title: 'ADP API Central for clients',
                            pathname: '/placeHolder-api-central-guide'
                        },
                        {
                            id: 'partner-guide',
                            title: 'ADP Marketplace partners',
                            pathname: '/placeHolder-partner-guide'
                        }
                    ]
                },
                {
                    id: 'guides-na',
                    title: 'Product Guides by Data type (North America)',
                    hasChildren: true,
                    children: [
                        {
                            id: 'guides-na-benefits-usecase',
                            title: 'Benefits',
                            pathname: '/placeholder-guides',
                        }
                    ]
                },
                {
                    id: 'guides-int',
                    title: 'Product Guides by Data type (International)',
                    hasChildren: true,
                    children: [
                        {
                            id: 'guides-int-ext-hr-usecase',
                            title: 'External HR & Data Connectors',
                            pathname: '/placeholder-int-guides',
                        }
                    ]
                },
                {
                    id: 'guides-marketplace-integration-guides',
                    title: 'ADP Marketplace Integration Guides',
                    hasChildren: true,
                    children: [
                        {
                            id: 'guides-mkpl-partner-onboarding',
                            title: 'Partner Onboarding',
                            pathname: '/placeholder',
                        }
                    ]
                }
            ]
        },
        {
            id: 'solutions',
            title: 'Solutions',
            hasChildren: true,
            expanded: true,
            children: [
                {
                    id: 'solutions-na',
                    title: 'Solutions for North American Products',
                    hasChildren: true,
                    children: [
                        {
                            id: 'solutions-na-1',
                            title: 'Human Resources',
                            pathname: '/placeHolder'
                        }
                    ]
                },
                {
                    id: 'solutions-international',
                    title: 'Solutions for International Products',
                    hasChildren: true,
                    children: [
                        {
                            id: 'solutions-int-1',
                            title: 'External HR & Data Connectors',
                            pathname: '/placeHolder'
                        }
                    ]
                }

            ]
        },
        {
            id: 'support',
            title: 'Support',
            pathname: '/support/help-center',

        }
    ];

    return [
        {
            appRoutes: fulfillRoutes(appRoutes),
        },
    ];
}
