import React from "react";
import { PartnerSection } from "../components/partner-integration/PartnerSection";
import { MarketplaceSection } from "../components/partner-integration/MarketplaceSection";
import { ClientSection } from "../components/partner-integration/ClientSection";
import { CommonlyAskedQuestions } from "../components/welcome-page/CommonlyAskedQuestions";
import { timeline2 } from "../components/welcome-page/utility/constants";
import AvailableGuides from "../components/ClientIntegration/AvailableGuides";
import { GUIDE_ITEM_LIST } from "../components/partner-integration/utility/constants";


export function PartnerIntegrationPage() {
  return (
    <div className="mt-32 items-center flex flex-col">
      <MarketplaceSection />
      <ClientSection />
      <PartnerSection timeline2={timeline2}/>
      <div className="overflow-hidden flex flex-col items-center py-20">
      <AvailableGuides guideItemList={GUIDE_ITEM_LIST}/>
      </div>
      <div className="min-w-full bg-action-default-50 relative">
      <CommonlyAskedQuestions />
      </div>

    </div>
  );
}
