import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
// import Breadcrumb from "../components/Breadcrumb";
import ArticleContent from "../components/articles/ArticleContent";
import {useFetchArticleQuery, useUpdateMetricsMutation} from "../store/";
import {SdfAlert} from "@synerg/react-components";
import {NotFound} from "./NotFound";
//import {getBreadcrumb} from "../utility/articlesUtils";
import {BusyPageIndicator} from "../components/BusyPageIndicator";

const routeMap = {
    'key-concepts': 'general',
    'how-to-articles': 'general',
    'faqs': 'faq',
}

function ViewArticlePage() {
    const {type, articleId} = useParams();

    let {isLoading, data} = useFetchArticleQuery({
        index: routeMap[type],
        id: articleId
    });

    // let breadcrumbPath = getBreadcrumb(type, getTitle(data));

    const [updateMetrics, results] = useUpdateMetricsMutation();

    useEffect(() => {
        updateMetrics({id:articleId, index: routeMap[type]});
    }, []);

    const errorMsg = results.error ?
        <>
            <SdfAlert closeable status="error">An error occurred when updating metrics for this document</SdfAlert>
        </>
        : <></>

    return (
        isLoading ?
            <BusyPageIndicator positionClass="absolute-center"/> :
            <div className="px-8">
                {/*<Breadcrumb path={breadcrumbPath}/>*/}
                {errorMsg}
                {!data && <NotFound/>}
                {data && <ArticleContent content={data}/>}
            </div>
    )
}

// function getTitle(articleItem) {
//     return articleItem?.title || articleItem?._source?.title || articleItem?._id || 'No Title';
// }


export default ViewArticlePage;
