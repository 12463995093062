import {
    SdfBox,
    SdfButton,
    SdfExpandableBox,
    SdfIcon,
    SdfIconButton,
    SdfLink,
    SdfTooltip
} from "@synerg/react-components";
import React from "react";
import {PageTitle} from "../../components/PageTitle";
import {adpIternationalProduct, adpNorthAmericaProduct} from "../../utility/articlesUtils";

import usCndIcon from '../../assets/images/us-cnd-icon.svg'
import usIcon from '../../assets/images/us-icon.svg'
import europeanGlobe from '../../assets/images/european-globe.png'
import useMediaQuery from "../../hooks/useMediaQuery";


function HelpCenterPage() {
    const onHandleGetHelp = (href) => {
        let a = document.createElement('a')
        a.href = href;
        a.target = "_blank"
        a.click()
    }

    const isBetweenMiddleAndLargeScreen = useMediaQuery('(min-width: 1407px)')

    const sections = [
        {
            title: 'North American Products',
            products: adpNorthAmericaProduct,
            commonStyle: '',
            adpMarketplacePartners: {
                icon: usCndIcon,
                bgColor: 'bg-alert-neutral-100',
                title: 'ADP Marketplace Partners',
                cards: [
                    {
                        style: !isBetweenMiddleAndLargeScreen ? 'lg:h-md' : 'lg:h-xs',
                        title: 'Third-party partner developers',
                        desc: <>If you’re an ADP Marketplace partner integrating with a North American ADP product,
                            submit a
                            ticket and track its progress through the <SdfLink
                                external href="https://partnersupport.adp.com/hc/en-us/requests" target="_blank">Partner
                                Help Center</SdfLink>.
                            A member of our support team will help you resolve your issue. </>,
                        href: "https://partnersupport.adp.com/hc/en-us/requests"
                    }
                ]
            },
            adpClients: {
                icon: usIcon,
                bgColor: 'bg-alert-neutral-50',
                title: 'ADP Clients',
                cards: [

                    {
                        style: !isBetweenMiddleAndLargeScreen ? 'lg:h-md' : 'lg:h-xs md:h-xs sm:h-xs',
                        title: 'ADP API Central users',
                        desc: <>If you’re an ADP client or other developer using ADP API Central for a North American
                            product, log in to <SdfLink
                                external href="https://api-central.adp.com/" target="_blank">ADP API
                                Central</SdfLink> to submit a ticket. Select the question mark icon <SdfIcon
                                icon="action-help"/> in the
                            upper-right corner of the application.</>,
                        bottomText: <>Not already using ADP API Central as a North American ADP client?<br></br>
                            <SdfLink className="font-bold"
                                     external href="https://apps.adp.com/en-US/apps/410612" target="_blank">Learn
                                more</SdfLink></>,
                        href: "https://api-central.adp.com/"
                    },
                    {
                        style: !isBetweenMiddleAndLargeScreen ? 'lg:h-md' : 'lg:h-xs md:h-xs sm:h-xs',
                        title: 'All other ADP clients',
                        desc: <>If you’re an ADP client who purchased a North American product and are having trouble
                            implementing ADP APIs and have not purchased ADP API Central, visit <SdfLink
                                className=""
                                external href="https://www.adp.com/contact-us/customer-service.aspx" target="_blank">
                                ADP
                                Customer Service &
                                Support.</SdfLink> </>,

                        href: "https://www.adp.com/contact-us/customer-service.aspx"
                    }
                ]
            }

        },
        {
            title: 'International Products',
            products: adpIternationalProduct,
            commonStyle: 'lg:h-xs',
            adpMarketplacePartners: {
                icon: europeanGlobe,
                bgColor: 'bg-alert-neutral-100',
                title: 'ADP Marketplace Partners',
                cards: [{
                    bgColor: 'bg-alert-info-50',
                    title: 'Marketplace partner developers',
                    desc: 'If you\'re an ADP Marketplace partner integrating with an ADP International product, select the button below to get help. A member of our international partner integration team will help you resolve your issue.',
                    href: 'mailto:Marketplace_ESI_Integration@adp.com'
                }]
            },
            adpClients: {
                icon: europeanGlobe,
                bgColor: 'bg-alert-neutral-50',
                title: 'ADP Clients',
                cards: [
                    {
                        bgColor: 'bg-alert-info-50',
                        title: 'ADP API Central (previously Developer Toolkit)',
                        desc: 'If you\'re an ADP Client integrating with an international ADP Product, select the button below to get help. A member of our international client support team will help you resolve your issue.',
                        href: 'mailto:Marketplace_ESI_Client_Support@adp.com'
                    }
                ]
            }
        }
    ]


    return (
        <div className='pb-12 px-4 md:pl-8 md:pr-0 mr-20'>
            <PageTitle className="text-center sm:text-left text-5xl font-black mb-4" text="Help center"/>
            <div className="font-black text-4xl">Product Integration Support</div>
            <div className="text-md pb-6 pt-3">Select the region where your ADP product is sold to be routed to the
                correct
                support
                channel.
            </div>

            {sections.map((section, index) => {
                return (
                    <SdfExpandableBox key={index} className="mb-4" variant="bordered" triggerPlacement="after">
                        <div slot="header" className="flex text-xl font-bold mb-1">
                            <span className="mb-px">{section.title}</span>
                            <SdfTooltip className="pl-2" tooltipId="alert-info" attachmentPoint="right">
                                <SdfIconButton slot="tooltip-target">
                                    <SdfIcon icon="alert-info" className="mt-px"></SdfIcon>
                                </SdfIconButton>
                                <span>
                                    {section.products.map((product, index) =>
                                        <div key={index} className="p-1">
                                            {product.label}
                                        </div>)}
                                </span>
                            </SdfTooltip>
                        </div>

                        <div className="flex flex-col lg:flex-row md:flex-col sm:flex-col mt-4 w-full max-h-auto">
                            <div
                                className={`flex flex-col items-center p-6 lg:w-3/5 ${section.adpMarketplacePartners.bgColor}`}>
                                <img src={section.adpMarketplacePartners.icon} alt="Us and Canada Icon"/>
                                <span className="font-bold text-xl py-4">{section.adpMarketplacePartners.title}</span>
                                {section.adpMarketplacePartners.cards.map((card, index) => (
                                    <div key={index} className={`flex flex-col`}>
                                        <SdfBox key={index} variant="shadowed"
                                                className={`${section.commonStyle} ${card.style} ${card.bgColor} flex flex-col justify-between p-6`}>
                                            <div className="">
                                                <div className="text-lg font-bold pb-3">{card.title}</div>
                                                <div className="font-normal leading-md">{card.desc}</div>
                                            </div>

                                            <div className="lg:mt-8 md:mt-8 mt-2">
                                                <SdfButton emphasis="primary" className=""
                                                           onClick={() => onHandleGetHelp(card.href)}>
                                                    Get help
                                                </SdfButton>
                                            </div>

                                        </SdfBox>
                                        <div className="mt-2">
                                            {card.bottomText && (card.bottomText)}
                                        </div>
                                    </div>

                                ))}
                            </div>
                            <div className={`flex flex-col items-center p-6 lg:w-full ${section.adpClients.bgColor} `}>
                                <img src={section.adpClients.icon} alt="Us and Canada Icon"/>
                                <span className="font-bold text-xl py-4">{section.adpClients.title}</span>
                                <div className="flex flex-col lg:flex-row md:flex-row gap-3">
                                    {section.adpClients.cards.map((card, index) => (
                                        <div key={index} className={`flex flex-col`}>
                                            <SdfBox key={index} variant="shadowed"
                                                    className={`${section.commonStyle} ${card.style} ${card.bgColor} flex flex-col justify-between p-6`}>
                                                <div className="">
                                                    <div className="text-lg font-bold pb-3">{card.title}</div>
                                                    <div
                                                        className="font-normal leading-md ">{card.desc}</div>
                                                </div>

                                                <div className="lg:mt-8 md:mt-8 mt-2">
                                                    <SdfButton emphasis="primary" className=""
                                                               onClick={() => onHandleGetHelp(card.href)}>
                                                        Get help
                                                    </SdfButton>
                                                </div>

                                            </SdfBox>
                                            <div className="mt-2 text-tertiary">
                                                {card.bottomText && (card.bottomText)}
                                            </div>
                                        </div>

                                    ))}
                                </div>

                            </div>
                        </div>
                    </SdfExpandableBox>
                )
            })}

        </div>
    )
}

export default HelpCenterPage;
