import { Link } from "react-router-dom";
import {
  SdfPromoBox,
  SdfBadge,
  SdfAlert,
  SdfBox,
  SdfIcon,
} from "@synerg/react-components";

import "./GetStarted.css";

const startItemList = [
  {
    header: "Employee Demographic Data",
    message:
      "Read all employee demographic data and related validation tables. Sensitive personal information is hidden by default and can be allowed in the application.",
    illustrationName: "people-1",
    pathName: "/apis/api-explorer?category=employee-demographic-data",
    type: "read",
  },
  {
    header: "Time & Attendance",
    message: "Read and update timecards and work schedules.",
    illustrationName: "time-clock",
    pathName: "/apis/api-explorer?category=time",
    type: "read",
  },
  {
    header: "New Hire Onboarding",
    message:
      "Send new hires, onboard completed hires, and read and rehire existing employees to ADP.",
    illustrationName: "teacher",
    pathName: "/apis/api-explorer?category=hire",
    type: "write",
  },
  {
    header: "Payroll Input",
    message:
      "Add pay-data batches for a payroll cycle to include earnings, deductions, reimbursements and more.",
    illustrationName: "money-hand",
    pathName: "/apis/api-explorer?category=payroll",
    type: "write",
  },
];

const needItemList = [
  {
    header: "Instant access to APIs",
    message:
      "API Central can be purchased online and digitally activated in minutes, giving instant access to your workforce data via ADP APIs.",
    illustrationName: "ext-mdi-assured-workload",
  },
  {
    header: "ADP API library",
    message:
      "Access ADP APIs across all supported ADP platforms, covering common use cases for custom integration.",
    illustrationName: "toolbox",
  },
  {
    header: "API use case templates",
    message:
      "Jumpstart your integration projects. Select a use case for your project and it will immediately populate with relevant APIs.",
    illustrationName: "ext-mdi-dashboard-customize",
  },
  {
    header: "API discovery tool",
    message:
      "Instantly add new APIs to any ADP API Central integration project. Once you add a new API, code samples are immediately available.",
    illustrationName: "action-search",
  },
  {
    header: "Developer resources",
    message:
      "Access documentation, how-to guides, code collection samples, developer libraries and more.",
    illustrationName: "ext-mdi-data-object",
  },
  {
    header: "Data access credentials",
    message:
      "Quickly create integration projects and security certificates and add development team members (internal or external).",
    illustrationName: "media-file",
  },
  {
    header: "Ticketed support",
    message:
      "Responsive experts monitor our ticketed support channel to help keep your project on track.",
    illustrationName: "action-chat-person",
  },
  {
    header: "Data security",
    message:
      "API Central leverages OpenID Connect and OAuth 2.0 to ensure only authorized users and systems can access your data.",
    illustrationName: "ext-mdi-laptop",
  },
];

const StartBox = ({ pathName, type, className, ...rest }) => {
  const boxProps = {
    className: `w-full h-full${className ? ` ${className}` : ""}`,
    size: "sm",
    variant: "bordered"
  };

  return (
    <div className="relative col-span-2 xl:col-span-1">
      <SdfPromoBox {...boxProps} {...rest}>
        <Link
          to={pathName}
          className="text-body-02 no-underline whitespace-nowrap"
          slot="cta"
        >
          View associated APIs
        </Link>
      </SdfPromoBox>
      <SdfBadge
        className={`absolute top-0 right-0 pt-4 pr-4`}
        status={type === "read" ? "in-progress" : "changed"}
      >
        {type === "read" ? "Read only" : "Read/Write"}
      </SdfBadge>
    </div>
  );
};

const NeedBox = ({ header, message, illustrationName, className, ...rest }) => {
  const boxProps = {
    className: `w-full h-full${className ? ` ${className}` : ""}`,
    variant: "bordered",
  };

  return (
    <div className="relative col-span-2 xl:col-span-1">
      <SdfBox {...boxProps} {...rest}>
        <div className="relative inline-block need-box-icon-container">
          <div className="absolute bg-alert-info-50 w-full h-full need-box-icon-shape" />
          <SdfIcon
            icon={illustrationName}
            className="text-3xl text-status-progress m-auto w-full h-full"
          />
        </div>
        <div className="m-0 py-2 text-heading-04">{header}</div>
        <div className="m-0 text-body-02 text-tertiary">{message}</div>
      </SdfBox>
    </div>
  );
};

const GetStarted = () => (
  <div className="w-11/12 md:w-4/5">
    <div className="flex flex-col items-center gap-4">
      <h2 className="m-0 text-heading-06">
        Get started with API use case templates
      </h2>
      <p className="m-0 text-body-02">
        When starting a new custom integration project, developers can select
        from a set of common API use case templates, pre-populated with relevant
        APIs, to begin development quickly. Use the API discovery tool to add
        more APIs as you need them.
      </p>
      <div className="w-full pt-12 grid grid-cols-4 auto-rows-fr gap-4">
        {startItemList.map((item, index) => (
          <StartBox key={`started-box-${index}`} {...item} />
        ))}
      </div>
      <div className="m-0 text-heading-04">
        ...plus add even more APIs to your starter project with the API
        discovery tool!
      </div>
      <SdfAlert icon="alert-notification" size="md" className="pt-4">
        <span slot="title">Sample templates are representative</span>
        <span>
          Pre-populated use cases vary by ADP product. The above examples and
          associated APIs are taken from one of ADP’s most popular products, ADP
          Workforce Now®.
        </span>
      </SdfAlert>
      <h3 className="m-0 pt-12 text-heading-06">
        Everything you need, all in one place
      </h3>
      <div className="w-full pt-4 pb-8 grid grid-cols-4 auto-rows-fr gap-4">
        {needItemList.map((item, index) => (
          <NeedBox key={`started-box-${index}`} {...item} />
        ))}
      </div>
    </div>
  </div>
);

export default GetStarted;
