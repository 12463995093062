import React, { useState, useRef, useEffect } from "react";
import { SdfButton, SdfTab, SdfTabGroup } from "@synerg/react-components";
import "./style.css";
import headerImg from "../../assets/images/new-graphic-v2.png";
import IntegrationSteps from "./IntegrationSteps";
import { TIMELINE_1_ITEMS, TIMELINE_2_ITEMS } from "./utility/constants";
import { useNavigate } from "react-router-dom";
import Timeline from "../shared/Timeline";

export function UseCases({ useCaseRoles }) {
  const [selectedTab, setSelectedTab] = useState(0);
  let navigate = useNavigate();

  function onHandleSwitchUseCase(idx) {
    idx === 0 ? setSelectedTab(0) : setSelectedTab(1);
  }

  function navigateGuide() {
    selectedTab === 0
      ? navigate("/getting-started/client-integration-overview")
      : navigate("/getting-started/partner-integration-overview");
  }

  const timelineRef = useRef(null);
  const [, setTimelineWidth] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      console.log("Resize==>", entries);
      setTimelineWidth(entry.contentRect.width);
    });

    if (timelineRef.current) {
      resizeObserver.observe(timelineRef.current);
    }

    return () => {
      if (timelineRef.current) {
        resizeObserver.unobserve(timelineRef.current);
      }
    };
  }, [timelineRef.current]);

  return (
    <div className="m-auto mt-16 items-center flex flex-col">
      <div className="bg-action-primary-900 rounded-lg py-4 px-24 w-3/5">
        <div className="flex flex-col lg:flex-row justify-between gap-6 lg:gap-10">
          <div className="pt-4">
            <div className="font-black text-inverse-default text-heading-06 mt-0 mb-6">
              Looking to integrate with your company’s data? Try ADP API Central
            </div>
            <p className="text-inverse-default mb-4 sm:mb-6 font-medium text-placeholder max-w-full lg:max-w-4/5">
              API Central makes it easy to access ADP APIs, tools and services,
              all in one place
            </p>
            <SdfButton
              id="demo-component"
              action="standard"
              emphasis="primary"
              size="sm"
              variant="contained"
              onClick={() =>
                navigate("/getting-started/client-integration-overview")
              }
              className="sm:pb-10 md:pb-10"
            >
              Learn more about API Central
            </SdfButton>
          </div>
          <div className="flex flex-shrink-0 justify-center items-center">
            <img
              src={headerImg}
              alt="API Central illustration"
              className="max-w-xs sm:max-w-sm md:max-w-md"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col m-auto w-11/12 md:w-4/5 mt-16 items-center">
          <label className="font-black text-heading-06">
            What to expect when integrating with ADP
          </label>
          <p className="p-2 text-center xl:w-3/4 custom-weight text-md leading-md">
            The integration process varies depending on whether you’re an ADP
            client looking to access your own company’s data or an ADP
            Marketplace partner selling your integration to clients on our
            digital storefront.
          </p>

          <div className="flex justify-center my-3">
            <section className="flex items-center justify-center h-full p-8">
              <SdfTabGroup className="w-sm" id="demo-component">
                {useCaseRoles.map((tab, idx) => (
                  <SdfTab
                    key={idx}
                    active={selectedTab === idx}
                    size="large"
                    onClick={() => onHandleSwitchUseCase(idx)}
                  >
                    {tab.label}
                  </SdfTab>
                ))}
              </SdfTabGroup>
            </section>
          </div>
          <div className="w-11/12 md:w-5/5 flex flex-col">
            <div>
              {selectedTab === 0 && (
                <>
                  <p className="font-bold text-heading-05 mb-16 text-center">
                    Integrating as an ADP Client
                  </p>

                  <div>
                    <Timeline
                      {...{
                        timelineItems: TIMELINE_1_ITEMS,
                        hideDetails: true,
                        timelineRef,
                        homePageTimeline: false,
                      }}
                    />
                  </div>
                </>
              )}
              {selectedTab === 1 && (
                <>
                  <p className="font-bold text-heading-05 mb-16 text-center">
                    Integrating as an ADP Marketplace Partner
                  </p>
                  <div>
                    <IntegrationSteps
                      timeline2Items={TIMELINE_2_ITEMS}
                      timelineRef={timelineRef}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {selectedTab === 0 && (
            <SdfButton
              onClick={() => navigateGuide()}
              emphasis="primary"
              size="lg"
              className="mt-4 pt-16"
              id="getStarted1"
            >
              Learn what’s available to ADP clients
            </SdfButton>
          )}
          {selectedTab === 1 && (
            <SdfButton
              onClick={() => navigateGuide()}
              emphasis="primary"
              size="lg"
              className="mt-4 pt-16"
              id="getStarted1"
            >
              Learn what’s available to ADP Marketplace partners
            </SdfButton>
          )}
        </div>
      </div>
    </div>
  );
}
