import React from "react";
import { SdfExpandableBox } from "@synerg/react-components";

export function CommonlyAskedQuestions() {
  return (
    <div className="flex flex-col m-auto py-6 max-w-3/4">
      <p className="text-heading-06 font-black text-center pb-4">
        Commonly asked questions
      </p>
      <section className="pb-12">
        <SdfExpandableBox
          className="h-16 m-1 text-sm"
          id="demo-component"
          header="Whats the difference between an ADP client and a Marketplace partner?"
          triggerPlacement="after"
          variant="shadowed"
          spacing="tight"
        >
          <div className="mt-4 body-02 text-body-02">
            Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque elit
            tincidunt turpis fames condimentum; molestie metus cursus a. maximus
            senectus aliquet, vestibulum rutrum fames habitasse mattis. Aliquam
            fames ex faucibus rutrum egestas aliquam dolor elementum. Ut
            sollicitudin dis montes dictum faucibus duis. Congue cubilia augue
            tincidunt sociosqu euismod. Massa nisi orci dignissim massa nullam
            potenti natoque
          </div>
        </SdfExpandableBox>
        <SdfExpandableBox
          className="h-16 m-1"
          id="demo-component"
          header="What do I need to get started calling ADP APIs?"
          triggerPlacement="after"
          variant="shadowed"
          spacing="tight"
        >
          <div className="mt-4 body-02 text-body-02">
            Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque elit
            tincidunt turpis fames condimentum; molestie metus cursus a. Dui
            maximus senectus aliquet, vestibulum rutrum fames habitasse mattis.
            Aliquam fames ex faucibus rutrum egestas aliquam dolor elementum. Ut
            sollicitudin dis montes dictum faucibus duis. Congue cubilia augue
            tincidunt sociosqu euismod. Massa nisi orci dignissim massa nullam
            potenti natoque.{" "}
            <p>
              {" "}
              Sed fringilla pharetra turpis neque molestie morbi nostra
              placerat. Egestas dictumst rhoncus eget sociosqu pellentesque
              ullamcorper tellus accumsan. Commodo facilisi ullamcorper non
              velit egestas. Lectus montes lacus vivamus volutpat ultricies
              primis mus. Mus pharetra cursus integer risus hendrerit, amet
              praesent. Taciti semper lacinia curae a, sagittis hac. Facilisis
              sollicitudin nam morbi consequat sollicitudin id pulvinar lobortis
              natoque. Nulla himenaeos ridiculus magnis efficitur a. Tempus
              consequat penatibus enim enim dui eu. Hac adipiscing vulputate nam
              lacinia auctor natoque nec.
            </p>
          </div>
        </SdfExpandableBox>
        <SdfExpandableBox
          className="h-16 m-1"
          id="demo-component"
          header="What type of data is accessible through ADP APIs?"
          triggerPlacement="after"
          variant="shadowed"
          spacing="tight"
        >
          <div className="mt-4 body-02 text-body-02">
            Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque elit
            tincidunt turpis fames condimentum; molestie metus cursus a. Dui
            maximus senectus aliquet, vestibulum rutrum fames habitasse mattis.
            Aliquam fames ex faucibus rutrum egestas aliquam dolor elementum. Ut
            sollicitudin dis montes dictum faucibus duis. Congue cubilia augue
            tincidunt sociosqu euismod. Massa nisi orci dignissim massa nullam
            potenti natoque.
          </div>
        </SdfExpandableBox>
        <SdfExpandableBox
          className="h-16 m-1"
          id="demo-component"
          header="Where can I learn more about how to purchase API Central as an ADP client?"
          triggerPlacement="after"
          variant="shadowed"
          spacing="tight"
        >
          <div className="mt-4 mb-4 body-02 text-body-02">
            Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque elit
            tincidunt turpis fames condimentum; molestie metus cursus a. Dui
            maximus senectus aliquet, vestibulum rutrum fames habitasse mattis.
            Aliquam fames ex faucibus rutrum egestas aliquam dolor elementum. Ut
            sollicitudin dis montes dictum faucibus duis. Congue cubilia augue
            tincidunt sociosqu euismod. Massa nisi orci dignissim massa nullam
            potenti natoque.{" "}
            <p>
              Sed fringilla pharetra turpis neque molestie morbi nostra
              placerat. Egestas dictumst rhoncus eget sociosqu pellentesque
              ullamcorper tellus accumsan. Commodo facilisi ullamcorper non
              velit egestas. Lectus montes lacus vivamus volutpat ultricies
              primis mus. Mus pharetra cursus integer risus hendrerit, amet
              praesent. Taciti semper lacinia curae a, sagittis hac. Facilisis
              sollicitudin nam morbi consequat sollicitudin id pulvinar lobortis
              natoque. Nulla himenaeos ridiculus magnis efficitur a. Tempus
              consequat penatibus enim enim dui eu. Hac adipiscing vulputate nam
              lacinia auctor natoque nec.
            </p>
          </div>
        </SdfExpandableBox>
      </section>
    </div>
  );
}
