import { useState } from "react";
import { SdfButton } from "@synerg/react-components";

const buyUrl = "TODO";

const BuyNow = () => {
  const [closed, setClosed] = useState(false);

  const buyProps = {
    className: "flex-shrink-0",
    emphasis: "primary",
    icon: "external-link",
    onClick: () => window.open(buyUrl, '_blank').focus(),
  };

  const closeProps = {
    className: "text-default",
    iconOnly: true,
    buttonTitle: "Clear Search",
    icon: "action-close",
    emphasis: "tertiary",
    onClick: () => setClosed(true),
  };

  if (closed) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-action-default-100 px-8 py-4 z-50">
      <div className="flex gap-8">
        <div className="flex-grow">
          <div className="lg:max-w-3/4">
            <h1 className="m-0 text-heading-05">
              Buy API Central for your development team
            </h1>
            <p className="m-0 text-body-02">
              ADP API Central is available on ADP Marketplace. Click{" "}
              <span className="font-bold">Get API Central</span> to view the
              application listing and share with your ADP system administrator
              to purchase.
            </p>
          </div>
        </div>
        <SdfButton {...buyProps}>Buy now</SdfButton>
        <div>
          <SdfButton {...closeProps} />
        </div>
      </div>
    </div>
  );
};

export default BuyNow;
