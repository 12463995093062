import React from 'react';
import Breadcrumb from '../../../components/Breadcrumb';
import { useEffect, useState } from 'react';
import {setFilters, useFetchElasticSearchQuery} from '../../../store';
import {
    SdfSelectSimple,
    SdfBox,
    SdfInput,
    SdfIcon,
    SdfSegmentedControl
} from "@synerg/react-components";
import { sortByOptions} from '../../../utility/FetchingUtils';
import LinkWithIcon from '../../../components/LinkWithIcon';
import get from 'lodash/get';
import { buildQueryObj } from '../../../utility/FetchingUtils';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectedUseCasePage } from '../../../store';
import {NoData} from "../../../components/NoData";
import {ARTICLES_BORDER_BOTTOM, ARTICLES_BORDER_TOP} from "../../../utility/constants";
import {PageTitle} from "../../../components/PageTitle";
import {isFetching} from "../../../utility/articlesUtils";
import {InputWidthEnum} from "../../../utility/enums/InputWidthEnum";
import {BusyPageIndicator} from "../../../components/BusyPageIndicator";

const breadcrumbPath = [
    { name: 'Home', route: '/' },
    { name: 'Integrations by Solution', route: '/build/integrations-by-solution' },
]

const regionSelect = [
    { label: 'North America', value: 'North America' },
    { label: 'International', value: 'International' }
]
    
function IntegrationsBySolution() {
    const filtersFromState = useSelector(state => state.filtersSlice)

    const [filterByKeyword, setFilterByKeyword] = useState('');
    const [useCaseQueryString, setUseCaseQueryString] = useState('');
    const [sortBy, setSortBy] = useState(filtersFromState.sort || sortByOptions['mostViewed']);
    const [region, setRegion] = useState(filtersFromState.region || regionSelect[0]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let useCaseResponse = useFetchElasticSearchQuery(buildQueryObj({ sort : sortBy, query : useCaseQueryString, index: 'usecases'}));
    let useCaseList;
    useEffect(() => {
        const timer = setTimeout(() => {
            setUseCaseQueryString(filterByKeyword);
        }, 500);
        return () => clearTimeout(timer);
    }, [filterByKeyword]);

    const searchChangeHandler = (e) => {
        setFilterByKeyword(e.target.value);
        if (e.keyCode === 13) {
            setUseCaseQueryString(filterByKeyword);
        }
    }

    const sortHandler = (e) => {
        const newSort = Object.values(sortByOptions).find(item => item.value === e.target.value.value);
        dispatch(
            setFilters({sort: newSort}
        ))
        setSortBy(newSort);
    }

    const regionHandler = (e) => {
        dispatch(
            setFilters({region: regionSelect[e.detail]}
        ))
        setRegion(regionSelect[e.detail]);
    }

    const onNavigationHandler = (useCase) => {
        const id = get(useCase, '_id', '');
        const index = get(useCase, '_index', '');
        const source = get(useCase, '_source', '');
        const title = source?.title;
        dispatch(selectedUseCasePage({ id, index, source }));
        const path = title === 'ADP® Embedded Payroll' ? 'embeddedpayroll' : id;
        navigate(`/build/integrations-by-solution/${index}/${path}`, {state: {id}});
    }

    if (useCaseResponse.error) {
        useCaseList = <h3>Error loading Integrations by Solution</h3>
    } else {
        useCaseList = (
            <>
                <section className="text-tertiary font-bold text-sm py-4 px-2">TOPIC</section>
                {
                    useCaseResponse?.data?.hits?.hits?.filter(useCase => get(useCase, '_source.region', '') === region.value).map((useCase, index) => {
                    let { title } = get(useCase, '_source', '');
                    return (
                        <div key={index} onClick={() => onNavigationHandler(useCase)} className='cursor-pointer hover:bg-action-default-100'>
                            <section className={`${ARTICLES_BORDER_TOP} flex justify-between items-center py-3 px-2`}>
                                <main>
                                    <div className="text-alert-info text-lg">{title}</div>
                                </main>
                                <aside><LinkWithIcon iconPosition="after" icon='nav-move-right' >View</LinkWithIcon>
                                </aside>
                            </section>
                        </div>
                        )
                    })
                }
                <div className={ARTICLES_BORDER_BOTTOM}/>
            </>
        )
    }
    const useCaseSortSearch =
        <div>
            <SdfBox className='box-spacing-default box-layout-default box-accent box-shape-rounded hydrated px-3'>
                <section className="flex sm:my-5 sm:mx-1 sm:flex-nowrap flex-wrap gap-2">
                    <SdfInput className="w-full max-w-full mr-2" onSdfInput={searchChangeHandler} placeholder="Search by keyword" label='Search'>
                        <SdfIcon icon="action-search" slot="after" className='text-alert-info' />
                    </SdfInput>
                    <SdfSelectSimple
                        items={Object.values(sortByOptions)}
                        value={sortBy}
                        onSdfChange={sortHandler}
                        label='Sort by'
                        inputWidth_md={InputWidthEnum.XL}
                    />
                </section>
            </SdfBox>
             <div className="flex justify-center my-3">
                <SdfSegmentedControl
                    items={regionSelect}
                    onSdfChange={regionHandler}
                    selectedIndex={regionSelect.findIndex((select) => select.value === region.value)}
                />
            </div>
        </div>

    const integrations = useCaseResponse?.data?.hits?.hits?.filter(useCase => get(useCase, '_source.region', '') === region.value).length ?
        useCaseList :
        <NoData/>

    return (
        <div className="md:px-12 px-4 min-h-screen">
            <Breadcrumb path={breadcrumbPath} />
            <PageTitle text="Integrations by Solution" />
            {useCaseSortSearch}
            {
                isFetching(useCaseResponse) ?
                    <BusyPageIndicator positionClass="list-loading"/> :
                    integrations
            }
        </div>
    )
}

export default IntegrationsBySolution;
