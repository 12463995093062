import { useState } from "react";
import { SdfPromoBox, SdfButton, SdfIcon } from "@synerg/react-components";

import "./Timeline.css";

// TODO - flow component uses promobox with variant: "clear" and align: "center"
/**
 * @typedef {{
 *  header: string,
 *  message: string,
 *  illustrationName: string,
 *  details: string
 * }} TimelineItem
 */

/**
 * @param {TimelineItem & { onClick?: () => void, focus: boolean, index: number }} props
 */
const TimeLineItem = ({ focus, index, ...rest }) => (
  <SdfPromoBox
    className={`pt-4 row-span-2 lg:row-span-1 lg:col-span-2 timeline-item${
      focus ? " timeline-focus" : ""
    }`}
    style={{ "--timeline-item-index": index }}
    variant='clear'
    align='center'
    size='sm'
    {...rest}
  />
);

const HomePageTimeline = ({
  focus,
  index,
  message,
  ...rest
}) => {
  const data = { ...message };
  const tasks = Object.values(data);
  const formattedMessage = tasks.map((task) => `\u2022 ${task} \n`);

  return (
    <SdfPromoBox
      className={`pt-4 row-span-2 lg:row-span-1 lg:col-span-2 timeline-item${
        focus ? " timeline-focus" : ""
      }`}
      style={{ "--timeline-item-index": index }}
      variant="clear"
      align="left"
      size="sm"
      message={formattedMessage}
      {...rest}
    />
  );
};

/**
 * @param {{
 *  timelineItems: Array<TimeLineItem>,
 *  hideDetails?: boolean,
 *  timelineRef?: React.RefObject<HTMLDivElement>
 * }} props
 */
const Timeline = ({
  timelineItems,
  hideDetails,
  timelineRef,
  homePageTimeline,
}) => {
  const [index, setIndex] = useState(0);
  const { header, details } = timelineItems[index] || {};

  const prevButtonProps = {
    className: "no-underline whitespace-nowrap",
    emphasis: "primary",
    variant: "text",
    onClick: () => {
      setIndex(
        (lastIndex) =>
          (lastIndex - 1 + timelineItems.length) % timelineItems.length
      );
    },
  };

  const nextButtonProps = {
    className: "",
    emphasis: "primary",
    variant: "text",
    onClick: () => {
      setIndex((lastIndex) => (lastIndex + 1) % timelineItems.length);
    },
  };

  return (
    <div
      className={`w-full bg-default overflow-hidden flex flex-col xl:flex-row ${
        hideDetails
          ? ""
          : " border-solid border-1 rounded-lg timeline-container"
      }`}
    >
      <div className='flex-grow p-4' ref={timelineRef}>
        <div
          className='relative grid grid-flow-row-dense grid-cols-2 auto-rows-fr lg:grid-flow-col-dense lg:grid-rows-2 lg:auto-cols-fr gap-4 timeline-grid'
          style={{ "--timeline-items": timelineItems.length }}
        >
          {!homePageTimeline &&
            timelineItems.map((item, currentIndex) => (
              <TimeLineItem
                key={`timeline-item-${currentIndex}`}
                index={currentIndex}
                focus={!hideDetails && currentIndex === index}
                onClick={() => setIndex(currentIndex)}
                {...item}
              />
            ))}
          {homePageTimeline &&
            timelineItems.map((item, currentIndex) => (
              <HomePageTimeline
                key={`timeline-item-${currentIndex}`}
                index={currentIndex}
                focus={!hideDetails && currentIndex === index}
                onClick={() => setIndex(currentIndex)}
                {...item}
              />
            ))}
        </div>
      </div>
      {!hideDetails && (
        <div className="flex-1 bg-alert-neutral-200 min-w-60 p-4">
          <div className="w-3/4 md:w-1/2 xl:w-full h-full m-auto flex flex-col gap-4">
            <div className="m-0 text-heading-05">{header}</div>
            <div className="m-0 text-body-02 flex-grow whitespace-pre-wrap">
              {details}
            </div>
            <div className="flex gap-2">
              <SdfButton {...prevButtonProps}>
                <SdfIcon icon="nav-move-previous" />
                <span> Prev</span>
              </SdfButton>
              <div className="flex-grow" />
              <SdfButton {...nextButtonProps}>
                <span>Next </span>
                <SdfIcon icon="nav-move-next" />
              </SdfButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Timeline;
