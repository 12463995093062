import React from "react";
import { SdfSelectSimple } from "@synerg/react-components";
import { PageTitle } from "../PageTitle";
import { InputWidthEnum } from "../../utility/enums/InputWidthEnum";
import logo from "../../assets/images/Purple-corporate-graphic.svg";
import { useNavigate } from "react-router-dom";

export function HeroHeader({ integrationOptions }) {
  const navigate = useNavigate();
  const getSelectedValue = (event) => {
    event.target.value.value === "0" ? navigate("/getting-started/client-integration-overview") : navigate("/getting-started/partner-integration-overview")
  }

  return (
    <div className="flex items-center bg-action-default-50 pl-4 md:pl-5 lg:pl-5 pr-8 h-auto pt-10 pb-8 m-0">
      <main className="m-auto items-center flex flex-col min-w-4/5">
        <section>
          <div>
            <PageTitle
              className="font-black text-heading-08"
              noMargin
              id="heroHeader"
            >
              Integrate with ADP’s API Platform
            </PageTitle>
          </div>
          <div
            className="pt-3 text-center sm:text-heading-04"
            id="heroDescription" 
          >
            Let us personalize your developer experience by answering the
            question below
          </div>
          <div className="items-center relative">
            <div className="logo--container">
              <img
                src={logo}
                alt="Not found"
                className="object-contain w-full"
              />
            </div>
            <div className="h-full w-4/5 mx-auto mt-12 mb-4">
              <SdfSelectSimple
                id="demo-component"
                items={integrationOptions.map((options) => {return { label: options.label, value: options.value}})}
                inputWidth_md={InputWidthEnum.FULL}
                label=""
                portal-enabled
                required-state="default"
                placeholder="How do you want to use ADP APIs?"
                className="mr-4 w-3/4 cursor-pointer"
                onSdfChange={(event) => getSelectedValue(event)}
              ></SdfSelectSimple>
              <sdf-button
                data-cvs-id="3"
                id="demo-component"
                action="standard"
                emphasis="primary"
                size="sm"
                variant="contained"
                icon="nav-move-next"
                icon-placement="after"
              >
                Let"s go
              </sdf-button>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}