import React from 'react';
import {FilterTypesEnum} from "../../../utility/enums/FilterTypesEnum";
import ArticlesListWithFilter from "../../../components/articles/ArticlesListWithFilter";
import {ArticlesFilterQueriesEnum} from "../../../utility/enums/ArticlesFilterQueriesEnum";
import {
    adpIternationalProduct,
    adpNorthAmericaProduct,
    productIntegrationcategories
} from "../../../utility/articlesUtils";
import {InputWidthEnum} from "../../../utility/enums/InputWidthEnum";
import {FILTERS} from "../../../components/articles/filters";

const breadcrumbPath = [
    {name: 'Home', route: '/home'},
    {name: "Product Integration Guides"}
];


const INPUT_WIDTH_MD = InputWidthEnum.FULL


function ProductIntegrationGuidesPage() {

    return (
        <ArticlesListWithFilter
            pageTitle="Product Integration Guides"
            navigatePath="/guides/product-integration-guides"
            breadcrumbPath={breadcrumbPath}
            fieldsDescriptions={['GUIDE TITLE/DESCRIPTION', 'LAST UPDATED']}
            filtersDefinition={[
                FILTERS.input,
                {
                    filterType: FilterTypesEnum.SelectMulti,
                    initialValue: [],
                    queryKey: ArticlesFilterQueriesEnum.API_PRODUCT,
                    selectItems: adpNorthAmericaProduct,
                    label: 'North America',
                    inputWidthMd: INPUT_WIDTH_MD,
                    customUrlKey: ArticlesFilterQueriesEnum.API_PRODUCT + 'Na'
                },
                {
                    filterType: FilterTypesEnum.SelectMulti,
                    initialValue: [],
                    queryKey: ArticlesFilterQueriesEnum.API_PRODUCT,
                    selectItems: adpIternationalProduct,
                    label: 'International',
                    inputWidthMd: INPUT_WIDTH_MD,
                    customUrlKey: ArticlesFilterQueriesEnum.API_PRODUCT + 'Int'
                },
                {
                    filterType: FilterTypesEnum.SelectMulti,
                    initialValue: [],
                    queryKey: ArticlesFilterQueriesEnum.USECASE_TAGS,
                    selectItems: productIntegrationcategories,
                    label: 'Browse by solutions',
                    inputWidthMd: INPUT_WIDTH_MD,
                },
                FILTERS.sortBy,
            ]}
            description={{
                illustrationName: "book",
                text: 'As an ADP client, use product-specific guides to implement APIs to access your own data. As a partner, use product-specific guides to integrate with your app to access subscribed client data.'
            }}
            customArticlesQuery={{
                tags: ['guide'],
                index: "guides",
                guideType: "productIntegration"
            }}
        />
    )
}

export default ProductIntegrationGuidesPage;
